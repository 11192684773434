export default{
    data(){
        return{
            ar:{
                headMsg:'قم باضافة حجز مسبق الان',
                nameLabel:'الاسم',
                phoneLabel:'رقم الهاتف',
                branchLabel:'الفرع',
                branchPlaceholder:'اختر فرع',
                serviceLabel:'الخدمة',
                servicePlaceholder:'اختر خدمة',
                dateLabel:'التاريخ',
                appointmentLabel:'الحجز',
                appointmentPlaceholder:'اختر ميعاد الحجز',
                appointmentTypeLabel:'نوع الحجز',
                appointmentType:{
                    physicalLabel:'مقابلة في الفرع',
                    onlineLabel:'اونلاين عن طريق زووم',
                },
                PriceLabel:'السعر',
                buttonLabel:'اضافة حجز',
                //////// Errors Msgs
                errMsgs:{
                    name:{
                        required:'الاسم مطلوب',
                        minLen:'الاسم لا يقل عن ٥ أحرف',
                        maxLen:'الاسم لايزيد عن ٢٥٥ حرف',
                    },
                    phone:{
                        required:'رقم الهاتف مطلوب',
                        numeric:'رقم الهاتف ينبغي ان يكون أرقام فقط',
                        minLen:'رقم الهاتف لايقل عن ١١ رقم',
                        maxLen:`رقم الهاتف لا يزيد عن ١١ رقم`,
                    },
                    selectedService:{
                        required:'الخدمة مطلوبة',
                    },
                    selectedBranch:{
                        required:'الفرع مطلوب',
                    },
                    date:{
                        required:'التاريخ مطلوب',
                    },
                    selectedAppointment:{
                        required:'ميعاد الحجز مطلوب',
                    },
                    appointmentType:{
                        required:'نوع الحجز مطلوب',
                    },
                },
                ///////////// Dialog Msgs
                dialogMsgs:{
                    noService:'عفواً الخدمة غير متاحة في هذا الفرع',
                    noAppointments:'عفواً لايوجد مواعيد فارغة لهذه الخدمة',
                    noPreReservation:'عفواً خدمة الحجز المسبق غير متاحة لهذه الخدمة'
                },
                ///////////// Dialog Buttons
                dialogbtns:{
                    okLabel:'موافق',
                    closeLabel:'غلق',
                }
            }
        }
    }
}