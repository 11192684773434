export default{
    data(){
        return{
            ar:{
                welcomeMsg:'Self booking service provided by Best Waiting',
                nameLabel:'الاسم',
                phoneLabel:'رقم الهاتف',
                serviceLabel:'اختر خدمة',
                buttonLabel:'احجز دورك',
                //////// Errors Msgs
                errMsgs:{
                    name:{
                        required:'الاسم مطلوب',
                        minLen:'الاسم لا يقل عن ٥ أحرف',
                        maxLen:'الاسم لايزيد عن ٢٥٥ حرف',
                    },
                    phone:{
                        required:'رقم الهاتف مطلوب',
                        numeric:'رقم الهاتف ينبغي ان يكون أرقام فقط',
                        minLen:'رقم الهاتف لايقل عن ١١ رقم',
                        maxLen:`رقم الهاتف لا يزيد عن ١١ رقم`,
                    },
                    selectedService:{
                        required:'الخدمة مطلوبة',
                    },
                }
            }
        }
    }
}
