export default{
    data(){
        return{
            en:{
                headLabel:'Oops!',
                notfoundLabel:'404 - Page not found',
                contentLabel:'Get your link from your service provider',
                buttonLabel:'Go To Home Page'
            }
        }
    }
}