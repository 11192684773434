<template>
  <div class="add-turn">
      <div class="loading" v-if="!show"><div><ProgressSpinner /><h4>Loading ...</h4></div></div>
      <div class="form-wrapper" v-if="show">
          <div class="head-text">
            <h4>{{lang.welcomeMsg}}</h4>
          </div>
          <div class="form" :class="{'ar':local=='ar'}">
            <div class="form-fields">
                <div class="form-group">
                  <label for="name">{{lang.nameLabel}}: </label>
                  <input
                    v-model="name"
                    @change="$v.name.$touch"
                    :class="{'border-danger':$v.name.$invalid && $v.name.$dirty}"
                    type="text" class="form-control" id="name"
                    autocomplete="name"
                    dir="auto"
                  >
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.name.required && $v.name.$dirty">{{lang.errMsgs.name.required}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.name.minLen && $v.name.$dirty">{{lang.errMsgs.name.minLen}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.name.maxLen && $v.name.$dirty">{{lang.errMsgs.name.maxLen}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phone">{{lang.phoneLabel}}</label>
                  <input
                    v-model="phone"
                    @change="$v.phone.$touch"
                    :class="{'border-danger':$v.phone.$invalid && $v.phone.$dirty}"
                    type="number" class="form-control" id="phone"
                    autocomplete="tel"
                    dir="auto"
                  >
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.phone.required && $v.phone.$dirty">{{lang.errMsgs.phone.required}}</small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.numeric && $v.phone.$dirty">
                      {{lang.errMsgs.phone.numeric}}
                    </small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.minLen && $v.phone.$dirty">
                      {{lang.errMsgs.phone.minLen}}
                    </small>
                    <small class="d-block text-danger" v-else-if="!$v.phone.maxLen && $v.phone.$dirty">
                      {{lang.errMsgs.phone.maxLen}}
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <Dropdown
                    v-model="selectedService"
                    @change="$v.selectedService.$touch"
                    :class="{'border-danger':$v.selectedService.$invalid && $v.selectedService.$dirty}"
                    :options="services" optionLabel="name" :placeholder="lang.serviceLabel"
                  />
                  <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.selectedService.required && $v.selectedService.$dirty">
                      {{lang.errMsgs.selectedService.required}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    :disabled="$v.$invalid"
                    @click.prevent="addTurn"
                    class="btn btn-primary"
                    :class="{'no-drop':$v.$invalid}"
                    type="submit"
                  >{{lang.buttonLabel}}</button>
                </div>
            </div>
            <div class="custom-class">
              <div class="img-wrapper">
                <img src="../assets/logo.png"  alt="">
              </div>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
import { required,maxLength,minLength,numeric} from 'vuelidate/lib/validators'
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import en from '../assets/languages/AddTurn_en.js'
import ar from '../assets/languages/AddTurn_ar.js'
import axios from'axios'
// @ is an alias to /src
export default {
  props:['branchId'],
  mixins:[en,ar],
  components: {ProgressSpinner,Dropdown},
  data(){
    return{
      show:false,
      name:'',
      phone:'',
      services:[],
      selectedService:'',
      reservationInfo:'',
      ////
      lang:'',
      local:'',
    }
  },
  validations:{
    name:{
      required,
      minLen:minLength(5),
      maxLen:maxLength(255)
    },
    phone:{
      required,
      numeric,
      minLen:minLength(11),
      maxLen:maxLength(11)
    },
    selectedService:{
      required
    },
  },
  created(){
    this.getLanguage();
    this.getServiceByBranchId(this.branchId);
  },
  methods:{
    getLanguage(){
      localStorage.getItem('language')
      if(localStorage.getItem('language')=='en'){
        this.lang=this.en
        this.local='en'
      }
      else if(localStorage.getItem('language')=='ar'){
        this.lang=this.ar
        this.local='ar'
      }
    },
    getServiceByBranchId(branchId){
      if(branchId){
        axios
        .post("/api/getServicesByBranchId",null,
        {
          params: {branch_id:branchId},
          headers: {'X-localization':this.local},
        }
        )
        .then(res=>{
           console.log(res.status);
          if(res.status==200){
            let data=res.data.services
            this.services=data
            this.show=true;
          }
          else
            this.$router.push({name:'NotFound'});
        })
      }
      else
        this.$router.push({name:'NotFound'});
    },
    addTurn(){
      if(!this.$v.$invalid){
        let data = {
          service_id:this.selectedService.id,
          name:this.name,
          phone:this.phone
        }
        this.show=false;
        axios
          .post("/api/addTurnWeb",data
        )
          .then(res => {
          if(res.status ==200){
              let reservationInfo=res.data.turnReservation;
              console.log(reservationInfo);

            reservationInfo['waiting'] = res.data.turnReservation.waiting;
              reservationInfo['service']= this.selectedService.name;
              // this.$store.dispatch('addTurn',reservationInfo);
              let ticketInfo = {
                /////////////////////// data needed for rating
                branchId:reservationInfo.branch_id,
                serviceId:reservationInfo.service_id,
                reservationId:reservationInfo.id,
                //////////////////////// data needed for ticket
                createdAt:reservationInfo.created_at,
                name:reservationInfo.name,
                phone:reservationInfo.phone,
                turnNumber:reservationInfo.turn_number,
                servicePrefix:reservationInfo.service_prefix=="" ? "" : reservationInfo.service_prefix+" -- ",
                waitingCustomer: reservationInfo.waiting,
                service:reservationInfo.service,
                message: reservationInfo.message,
              }
              localStorage.setItem("ticketInfo",JSON.stringify(ticketInfo))
              this.show=true;
            this.$router.push({ name: "Reservation"})
          }
          else
            this.$router.push({name:'NotFound'});
        })
      }
      else
        console.log("disable attribute is deleted from console mode")
    },
  }
};
</script>
<style lang="scss">
.add-turn{
  padding: 20px;
  width: 100%;
  height:calc(100% - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  .loading{
    width:50%;
    height:50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000000,0.5);
    border-radius: 15px;
    h4{color: white;}
  }
  .form-wrapper{
    width:50%;
    margin: 0 auto;
    padding:30px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    .head-text{
      h4{
        text-align: center;
        font-weight: bold;
        margin: 20px auto;
      }
    }
    .form{
      display: flex;
      .form-fields{
        flex-grow: 1;
        .form-group{
          .no-drop{
            cursor: no-drop;
          }
          .p-dropdown{
            width: 100%;
          }
        }
      }
      .custom-class{
        flex-grow: 1;
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
    }
    .ar{
      display: flex;
      flex-direction: row-reverse;
      .form-fields{
        flex-grow: 1;
        text-align: right;
        .form-group{
          .no-drop{
            cursor: no-drop;
          }
          .p-dropdown{
            width: 100%;
          }
        }
      }
      .custom-class{
        flex-grow: 1;
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
            //-webkit-transform: scaleX(-1);
           // transform: scaleX(-1);
          }
        }
      }
    }
  }
}
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke:white;
    }
}
// @media (max-width:426px) {
//   .add-turn{
//     height:calc(100% - 56px);
//   }
// }
@media (max-width: 767.98px) {
  .add-turn{
    .loading{width: 90%}
    .form-wrapper{
      width:90%;
        .head-text{
          h4{
            font-size: 20px;
          }
        }
      .form{
        .custom-class{
          display: none;
        }
      }
    }
  }
}
@media (min-width: 768px ) and (max-width:1023px) {
  .add-turn{
    .loading{width: 90%}
    .form-wrapper{
      width:90%;
      .form{
      .custom-class{
        .img-wrapper{
            width:200px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
      }
    }
  }
}
@media (min-width:1024px ) and (max-width:1440px) {
  .add-turn{
    .loading{width: 80%}
    .form-wrapper{
      width:80%;
      .form{
      .custom-class{
        .img-wrapper{
            width:300px;
            height:308px;
            margin: 0 auto;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
      }
    }
  }
}

</style>
