
import axios from 'axios';

axios.defaults.baseURL = 'https://qbest.app';

// axios.defaults.baseURL = 'http://192.168.8.12/offline.queue.best';




