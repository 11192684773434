export default{
    data(){
        return{
            en:{
                welcomeMsg:'Self booking service provided by Best Waiting',
                nameLabel:'Name',
                phoneLabel:'Phone Number',
                serviceLabel:'Choose A Service',
                buttonLabel:'Get Your Turn',
                //////// Error Msgs
                errMsgs:{
                    name:{
                        required:'Name is required',
                        minLen:'Name should be at least 5 characters',
                        maxLen:'Name cant be more than 255 charcters',
                    },
                    phone:{
                        required:'Phone number is required',
                        numeric:'Phone number should be numbers only',
                        minLen:'Phone number should be at least 11 digit',
                        maxLen:`Phone number can't be more than 11 digit`,
                    },
                    selectedService:{
                        required:'Service is required',
                    },
                }
            }
        }
    }
}
