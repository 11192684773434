export default{
    data(){
        return{
            en:{
                opinionMsg:'Your Opinion Is Important',
                tellMSg:'Please tell us',
                textPlaceholder:'Tell Us What You Think ...',
                buttonLabel:'Send',
                dialogMsgs:{
                    success:"Your Rate Successfully Sent :)"
                }
            }
        }
    }
}