export default{
    data(){
        return{
            ar:{
                welcomeMsg:'مرحبا',
                dateLabel:': التاريخ',
                timeLabel:': الوقت',
                waitingLabel:': عملاء في الانتظار',
                serviceLabel:': الخدمة',
                turnLabel:': رقم الدور :',
                nameLabel:': الاسم',
                phoneLabel:': رقم الهاتف',
                warninigLabel:'تحديد نوع التطعيم حسب المتوفر و حسب رؤية طبيب وحدة التطعيم',
                rateLabel:'رأيك يهمنا',
            }
        }
    }
}
