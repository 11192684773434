export default{
    data(){
        return{
            en:{
                headLabel:'Oops!',
                notfoundLabel:'404 - Page not found',
                contentLabel:'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
                buttonLabel:'Go To Home Page'
            }
        }
    }
}