<template>
    <div class="rating" v-if="show">
        <div class="rating-wrapper" :class="{'ar':local=='ar'}">
            <h6>{{lang.opinionMsg}}</h6>
            <small>{{lang.tellMSg}}</small>
            <Rating v-model="rate" :cancel="false" />
            <div class="form-group mt-2 mb-0">
                <textarea 
                    v-model="comment" 
                    @change="$v.comment.$touch"
                    :class="{'border-danger':$v.comment.$invalid && $v.comment.$dirty}"
                    class="form-control" 
                    rows="3"
                    resize="auto"
                    :placeholder="lang.textPlaceholder"
                    dir="auto"
                >
                </textarea>
                <div class="err-msgs p-1">
                    <small class="d-block text-danger" v-if="!$v.comment.minLen && $v.comment.$dirty">comment should be at least 5 characters</small>
                    <small class="d-block text-danger" v-else-if="!$v.comment.maxLen && $v.comment.$dirty">comment cant be more than 255 charcters</small>
                </div>
                <button
                    :disabled="$v.$invalid || !rate"
                    @click.prevent="addRate"
                    class="btn btn-primary w-100"
                    :class="{'no-drop':$v.$invalid}"
                    type="submit" 
                >
                {{lang.buttonLabel}}
                </button>
            </div>
        </div>
        <Dialog header="Success" :visible.sync="rateDialog" :style="{width: '350px'}" :modal="true">
          <div class="confirmation-content" :class="{'ar-dialog':local=='ar'}">
              <div class="icon-wrapper">
                <i class="pi pi-check" style="font-size: 2rem" />
              </div>
              <div class="text-wrapper">
                  <span>{{lang.dialogMsgs.success}}</span>
              </div>
          </div>
          <template #footer>
              <Button label="Close" icon="pi pi-times" @click="closeRateDialog" class="p-button-text"/>
              <Button label="Ok" icon="pi pi-check" @click="ratingIsDone" class="p-button-text" autofocus />
          </template>
      </Dialog> 
    </div>
</template>
<script>
import Rating from 'primevue/rating';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {maxLength,minLength} from 'vuelidate/lib/validators'
import en from '../assets/languages/Rating_en.js'
import ar from '../assets/languages/Rating_ar.js'
import axios from'axios'
export default {
    props:['reservationId'],
    mixins:[en,ar],
    components:{Rating,Dialog,Button},
    data(){
        return{
            rate:3,
            comment:'',
            ticketInfo:'',
            show:false,
            rateDialog:false,
            //////////
            lang:'',
            local:'',
        }
    },
    validations:{
        comment:{
            minLen:minLength(5),
            maxLen:maxLength(255)
        },
    },
    created(){
        this.getLanguage();
        this.getReservation();
    },
    methods:{
        getLanguage(){
            localStorage.getItem('language')
            if(localStorage.getItem('language')=='en'){
                this.lang=this.en
                this.local='en'
            }
            else if(localStorage.getItem('language')=='ar'){
                this.lang=this.ar
                this.local='ar'
            }
        },
        getReservation(){
            if(this.reservationId){
                let data={
                    id:this.reservationId
                }
                axios
                .post("/api/getReservation",data)
                .then(res=>{
                    if(res.data.status){
                        if(res.data.reservation!=null){
                            let data=res.data['reservation']
                            this.ticketInfo=data
                            setTimeout(()=>{this.show=true;},100)
                        }
                        else
                            this.$router.push({name:'NotFound'});
                    }
                    else
                        this.$router.push({name:'NotFound'});
                })
            }
        },
        addRate(){
            if(this.rate && this.ticketInfo){
                let data={
                    branch_id:this.ticketInfo.branch_id,
                    service_id:this.ticketInfo.service_id,
                    reservation_id:this.ticketInfo.id,
                    name:this.ticketInfo.name,
                    phone:this.ticketInfo.phone,
                    rate:this.rate,
                    comment:this.comment,
                }
                axios
                .post("/api/addRate",data
                )
                .then(res=>{
                    if(res.data.status){
                        this.rate=3;
                        this.comment='';
                        this.rateDialog=true
                    }
                    else
                        this.$router.push({name:'NotFound'});
                })
                .catch(err=>{
                    console.log(err)
                })
            }
        },
        closeRateDialog(){
            this.rateDialog=false
        },
        ratingIsDone(){
            window.location.href="https://qbest.app/"
        },
    },
}
</script>
<style lang="scss">
.rating{
  width: 100%;
  height:calc(100% - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  .rating-wrapper{
    width:90%;
    margin: 0 auto;
    padding:30px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    textarea::placeholder{
        font-size: 14px;
    }
    h6{
        font-size: 20px;
    }
    small{
        margin-top: 8px;
    }
    .p-rating{
        display: flex;
        margin-top:15px;
        .p-rating-icon.pi-star{
            color: gold !important;
            font-size: 2rem;
        }
        .p-rating-icon.pi.pi-star-o{
            font-size: 2rem;
        }
    }
  }
  .ar{
      textarea::placeholder{
            text-align:right;   
      }
    .p-rating{
        flex-direction: row-reverse;
    }
  }
  .confirmation-content{
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-wrapper{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px;
      color:green;
    }
    .text-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px;
      height: 100%;
    }
  }
  .ar-dialog{
    text-align: right;
    // flex-direction: row-reverse;
    .icon-wrapper{
      flex-direction: row-reverse;
    }
  }
}
@media (min-width:769px) {
  .rating {
    .rating-wrapper{
        width:50%;
    }
  }
}
@media (min-width:1025px) {
  .rating {
    .rating-wrapper{
        width:30%;
    }
  }
}
</style>