import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    turn:'',
    booking:'',
    bookingList:[],
  },
  mutations: {
    'ADD_TURN'(state,data){
      if(data){
        let object={
          /////////////////////// data needed for rating
          branchId:data.branch_id,
          serviceId:data.service_id,
          reservationId:data.id,
          //////////////////////// data needed for ticket
          createdAt:data.created_at,
          name:data.name,
          phone:data.phone,
          turnNumber:data.turn_number,
          servicePrefix:data.service_prefix,
          waitingCustomer:data.waiting_customer,
          service:data.service
        }
        state.turn=object
      }
      else
        state.turn='';
    },
    'ADD_BOOKING'(state,data){
      if(data){
        let object={
          id:data.id,
          createdAt:data.created_at,
          name:data.name,
          phone:data.phone,
          date:data.date,
          bookingType:data.booking_type,
          startTime:data.start_time,
          endTime:data.end_time,
          price:data.price,
        }
        state.booking=object;
      }
      else
        state.booking='';
    },
    'ADD_BOOKING_LIST'(state,data){
      state.bookingList=[];
      if(data.length){
        data.forEach(el => {
          let object={
            id:el.id,
            createdAt:data.created_at,
            name:el.name,
            phone:el.phone,
            date:el.date,
            bookingType:el.booking_type,
            startTime:el.start_time,
            endTime:el.end_time,
            price:el.price,
          }
          state.bookingList.push(object);
        });
      }
      else
        state.bookingList=[];
    }
  },
  actions: {
    addTurn({commit},data){
      commit('ADD_TURN',data);
    },
    addBooking({commit},data){
      commit('ADD_BOOKING',data);
    },
    addBookingList({commit},data){
      commit('ADD_BOOKING_LIST',data);
    },
  },
  getters:{
    get_turn(state){
      return state.turn
    },
    get_booking(state){
      return state.booking
    },
    get_booking_list(state){
      return state.bookingList
    },
  },
  modules: {},
});
