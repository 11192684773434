import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
//fontawesom
// import "font-awesome/css/font-awesome.min.css";
//axios
import"./axios.js";
//primeVue 
import PrimeVue from 'primevue/config';
//primeflex
// import 'primeflex/primeflex.css';
//primestyle
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
Vue.use(PrimeVue);
//vuelidate 
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
//QrCode
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.use(VueQRCodeComponent)
Vue.component('qr-code', VueQRCodeComponent)
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
