<template>
  <div class="turn" v-if="show">
    <!-- <div class="container"> -->
    <div class="ticket-wrapper">
        <div class="logo">
            <div class="img-wrapper">
              <img src="../assets/check.png" alt="Logo Picture">
            </div>
        </div>
        <div class="user-details" :class="{'ar':local=='ar'}">
            <div><h4><span>{{lang.welcomeMsg}}</span><span> {{ticketInfo.name}}</span></h4></div>
            <div>
              <span><i class="pi pi-calendar"></i></span>
              <span>{{lang.dateLabel}}</span><span>{{new Date (ticketInfo.createdAt).toLocaleDateString()}}</span>
              <span>{{lang.timeLabel}}</span><span>{{new Date (ticketInfo.createdAt).toLocaleTimeString()}}</span>
            </div>
            <div>
              <span><i class="pi pi-paperclip"></i></span><span>{{lang.serviceLabel}}</span>
              <span>{{ticketInfo.service}}</span>
            </div>
            <div>
              <span><i class="pi pi-ticket"></i></span>
              <span>{{lang.turnLabel}}</span>
              <span>{{ticketInfo.servicePrefix}}  {{ticketInfo.turnNumber}}</span>
            </div>
            <div>
              <span><i class="pi pi-spinner"></i></span><span>{{lang.waitingLabel}}</span> 
              <span>{{ticketInfo.waitingCustomer}}</span>
            </div>
            <div><span><i class="pi pi-user"></i></span> <span>{{lang.nameLabel}}</span><span>{{ticketInfo.name}}</span></div>
            <div><span><i class="pi pi-phone"></i> </span><span>{{lang.phoneLabel}}</span><span>{{ticketInfo.phone}}</span></div>
            <div><span><i class="pi"></i> </span><span>{{ticketInfo.message}}</span></div>
        </div>
        <div class="qr">
            <h6 class="text-center"><router-link :to="`/rating/${ticketInfo.reservationId}`">
            <span><i class="pi pi-star-o mx-2" aria-hidden="true"></i></span>{{lang.rateLabel}}</router-link></h6>
        </div>
    </div>
  </div>
</template>
<script>
import en from '../assets/languages/Turn_en.js'
import ar from '../assets/languages/Turn_ar.js'
export default {
  mixins:[en,ar],
  data(){
    return{
      size: 300,
      ticketInfo:'',
      show:false,
      //////////////
      lang:'',
      local:'',
    }
  },
  created(){
    this.getLanguage();
    this.ticketInfo=JSON.parse(localStorage.getItem("ticketInfo"));
    // this.ticketInfo=this.$store.getters.get_turn;
    // this.qrValue=`${this.domainUrl}/rating/${this.ticketInfo.reservationId}`;
    setTimeout(()=>{
      if(this.ticketInfo)
          this.show=true;
      else
        this.$router.push({name:'NotFound'});
    }
    ,100)
  },
  methods:{
    getLanguage(){
      localStorage.getItem('language')
      if(localStorage.getItem('language')=='en'){
        this.lang=this.en
        this.local='en'
      }
      else if(localStorage.getItem('language')=='ar'){
        this.lang=this.ar
        this.local='ar'
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@mixin saw-tooth-vertical($width:30px, $bg:rgb(41, 41, 41)) {
  background-image:
    /* Top jagged */
    linear-gradient(135deg, $bg 50%, transparent 50%),
    linear-gradient(225deg, $bg 50%, transparent 50%),
    /* Bottom jagged */
    linear-gradient(45deg, $bg 50%, transparent 50%),
    linear-gradient(-45deg, $bg 50%, transparent 50%);
  background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;
  background-size: $width $width;
  background-repeat: repeat-x;
}
.turn{
  width: 100%;
  height:calc(100% - 82px);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ticket-wrapper{
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 15px;
    background-color: white;
    // @include saw-tooth-vertical();
    .logo{
      width: 100%;
      height:100%;
      margin: 10px;
      display: flex;
      justify-content: center;
      .img-wrapper{
        width: 150px;
        height: 150px;
        img{
          border-radius:50%;
          width: 100%;
          height: 100%;
        }
      }
    }
    .user-details{
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom:10px;
      div{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 0px;
        text-transform: capitalize;
        h4{
          display: flex;
          justify-content:center;
          flex-wrap: wrap;
          margin-bottom:20px;
        }
        span{
          margin: 0px 2px;
          i{
            margin: 0px 5px;
          }
        }
      }
    }
    .ar{
      div{
          flex-direction: row-reverse;
      }
      div{
        h4{
          flex-direction: row-reverse;
        }
      }
    }  
  }
}
@media (max-width:375px) {
.turn{
  h4,h6{
    span{
      // font-size: 16px;
    }
  }
  span{
    // font-size: 12px;
  }
}
}
</style>
