export default{
    data(){
        return{
            ar:{
                headLabel:'!عفواً',
                notfoundLabel:'404 - Page not found',
                contentLabel:'إحصل على الرابط الصحيح من خلال موزع خدمتك',
                buttonLabel:'الذهاب الي الصفحة الرئيسية'
            }
        }
    }
}