<template>
    <div class="not-found">
        <div class="notfound-404">
            <h1>{{lang.headLabel}}</h1>
            <h2>{{lang.notfoundLabel}}</h2>
            <p>{{lang.contentLabel}}</p>
            <a href="https://qbest.app"  class="btn btn-primary">{{lang.buttonLabel}}</a>
        </div>
    </div>
</template>
<script>
import en from '../assets/languages/NotFound_en.js'
import ar from '../assets/languages/NotFound_ar.js'
export default {
    mixins:[en,ar],
        data(){
        return{
            lang:'',
            // local:'',
        }
    },
    created(){
      this.getLanguage();
    },
    methods:{
        getLanguage(){
          localStorage.getItem('language')
          if(localStorage.getItem('language')=='en')
              this.lang=this.en
          else if(localStorage.getItem('language')=='ar')
              this.lang=this.ar
        },
    },
}
</script>
<style lang="scss" scoped>
.not-found{
    width: 100%;
    height:calc(100% - 82px);
    display: flex;
    justify-content: center;
    align-items: center;
    .notfound-404{
        width:90%;
        margin: 0 auto;
        padding:30px;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1{
            font-size: 6rem;
            background:url('../assets/images.jpg');
            background-position: bottom;
            -webkit-background-clip:text;
            color: transparent;
        }
        p{
          text-align: center;
        }
    }
}
@media (max-width:426px) {
  .not-found {
    height:calc(100% - 56px);
    .notfound-404{
        width:90%;
        h2{
            font-size:24px;
        }
    }
  }
}
@media (min-width:769px) {
  .not-found {
    .notfound-404{
        width:70%;
    }
  }
}
@media (min-width:1025px) {
  .not-found {
    .notfound-404{
        width:50%;
    }
  }
}
</style>