<template>
  <div class="booking" v-if="show">
    <div class="ticket-wrapper">
        <div class="logo">
            <div class="img-wrapper">
              <img src="../assets/logo.png" alt="Logo Picture">
            </div>
        </div>
        <div class="user-details" :class="{'ar':local=='ar'}">
            <div><h4><span>{{lang.welcomeMsg}}</span><span>{{ticketInfo.name}}</span></h4></div>
            <div><span><i class="pi pi-calendar"></i></span> {{lang.dateLabel}} <span> {{new Date (ticketInfo.date).toLocaleDateString()}}</span></div>
            <div><span><i class="pi pi-calendar"></i></span> {{lang.timeLabel}} <span> {{lang.fromLabel}} ( {{ticketInfo.startTime}} ) {{lang.toLabel}} ( {{ticketInfo.endTime}} )</span></div>
            <div><span><i class="pi pi-id-card"></i></span> {{lang.idLabel}}<span> {{ticketInfo.id}}</span></div>
            <div v-if="ticketInfo.bookingType==1"><span><i class="pi pi-paperclip"></i></span> {{lang.typeLabel}}<span> {{lang.physicalLabel}}</span></div>
            <div v-if="ticketInfo.bookingType==2"><span><i class="pi pi-paperclip"></i></span> {{lang.typeLabel}} <span> {{lang.onlineLabel}}</span></div>
            <div><span><i class="pi pi-user"></i></span> {{lang.nameLabel}} <span> {{ticketInfo.name}}</span></div>
            <div><span><i class="pi pi-phone"></i> </span> {{lang.phoneLabel}} <span> {{ticketInfo.phone}}</span></div>
        </div>
    </div>
  </div>
</template>
<script>
import en from '../assets/languages/Booking_en.js'
import ar from '../assets/languages/Booking_ar.js'
export default {
  mixins:[en,ar],
  data(){
    return{
      qrValue:'https://www.google.com/', /// will change later in deployment
      size: 300,
      ticketInfo:'',
      show:false,
      ////
      lang:'',
      local:'',
    }
  },
  created(){
    this.getLanguage();
    this.ticketInfo=this.$store.getters.get_booking;
    setTimeout(()=>{
      if(this.ticketInfo)
          this.show=true;
      else
        this.$router.push({name:'NotFound'});
    }
    ,100)
  },
  methods:{
      getLanguage(){
      localStorage.getItem('language')
      if(localStorage.getItem('language')=='en'){
        this.lang=this.en
        this.local='en'
      }
      else if(localStorage.getItem('language')=='ar'){
        this.lang=this.ar
        this.local='ar'
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@mixin saw-tooth-vertical($width:30px, $bg:#d41010) {
  background-image:
    /* Top jagged */
    linear-gradient(135deg, $bg 50%, transparent 50%),
    linear-gradient(225deg, $bg 50%, transparent 50%),
    /* Bottom jagged */
    linear-gradient(45deg, $bg 50%, transparent 50%),
    linear-gradient(-45deg, $bg 50%, transparent 50%);
  background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;
  background-size: $width $width;
  background-repeat: repeat-x;
}
.booking{
  width: 100%;
  height:calc(100% - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  .ticket-wrapper{
    width: fit-content;
    padding: 20px;
    background-color: white;
    box-shadow: #d41010;
    @include saw-tooth-vertical();
    display: flex;
    align-items: center;
    flex-direction: column;
    .logo{
      width: 100%;
      height:100%;
      margin-top: 40px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      .img-wrapper{
        width: 150px;
        height: 150px;
        img{
          border-radius:50%;
          width: 100%;
          height: 100%;
        }
      }
    }
    .user-details{
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom: 30px;
      div{
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        margin: 10px 0px;
        h4{
          display: flex;
          justify-content:center;
          margin-bottom:20px;
        }
        span{
          margin: 0px 2px;
          i{
            margin: 0px 5px;
          }
        }
      }
    }
    .ar{
      div{
          flex-direction: row-reverse;
      }
      div{
        h4{
          flex-direction: row-reverse;
        }
      }
    }  
  }
}
</style>
