export default{
    data(){
        return{
            ar:{
                inputPlaceholder:'ادخل رقم هاتفك',
                submitLabel:'حجوزاتي',
                //////// Errors Msgs
                errMsgs:{
                    phone:{
                        required:'رقم الهاتف مطلوب',
                        numeric:'رقم الهاتف ينبغي ان يكون أرقام فقط',
                        minLen:'رقم الهاتف لايقل عن ١١ رقم',
                        maxLen:`رقم الهاتف لا يزيد عن ١١ رقم`,
                    },
                },
                /////////////////////////////////////
                emptyMsg:'قائمة الحجوزات لديك فارغة',
                goLabel:'الذهاب الي الصفحة الرئيسية',
                ////////////////////////////////////////
                welcomeMsg:'مرحبا',
                dateLabel:': التاريخ',
                timeLabel:': الوقت',
                fromLabel:'من',
                toLabel:'إلى',
                idLabel:': رقم الحجز',
                typeLabel:': نوع الحجز',
                physicalLabel:'مقابلة في الفرع',
                onlineLabel:'اونلاين عن طريق زووم',
                nameLabel:': الاسم',
                phoneLabel:': رقم الهاتف',
            }
        }
    }
}