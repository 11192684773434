<template>
  <div id="app" v-if="show">
    <div id="nav">
      <div>
        <a href="https://qbest.app">Home</a>
        <router-link to="/booking-list">Booking List</router-link>
      </div>
      <div class="form-group mb-0">
        <Dropdown
          v-model="selectedLanguage"
          :options="languages" optionLabel="name" placeholder="Select a language"
        />
      </div>

    </div>
    <router-view />
  </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';
export default{
  components:{Dropdown},
  data(){
    return{
      show:false,
      selectedLanguage:'',
      languages:[
        {name:'English',value:'en'},
        {name:'عربي',value:'ar'}
      ],
    }
  },
  created(){
    this.setLanguage()
  },
  watch:{
    selectedLanguage(){
          if(this.selectedLanguage.value=='en'){
            setTimeout(()=>this.show=false,10)
            localStorage.setItem('language','en') ;
            setTimeout(()=>this.show=true,10)
          }
          else if(this.selectedLanguage.value=='ar'){
            setTimeout(()=>this.show=false,100)
            localStorage.setItem('language','ar') ;
            setTimeout(()=>this.show=true,100)

          }
    }
  },
  methods:{
    setLanguage(){
        if(localStorage.getItem('language')==null){
        localStorage.setItem('language','en');
        this.selectedLanguage={name:'English',value:'en'};
        setTimeout(()=>this.show=true,100)
      }
      else if((localStorage.getItem('language'))=='en'){
        this.selectedLanguage={name:'English',value:'en'};
        setTimeout(()=>this.show=true,100)
      }
      else if((localStorage.getItem('language'))=='ar'){
        this.selectedLanguage={name:'عربي',value:'ar'};
        setTimeout(()=>this.show=true,100)
      }
    },
  },
}
</script>
<style lang="scss">
:root{
  --primaryColor:#d41010;
  --secondaryColor:#d41010;
}
html,body{
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-image: -webkit-linear-gradient(0deg,var(--primaryColor) 0%,var(--primaryColor) 100%);
}
#app {
  font-family: Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  #nav {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #d41010;
    a {
      font-weight: bold;
      color:white;
      text-decoration:none;
      margin: 0px 10px;
      &:hover{
        color: var(--secondaryColor);
        padding-bottom: 5px;
        border-bottom: 3px solid var(--secondaryColor);
      }
    }
  }
}
@media (max-width: 767.98px) {
    #app{
      #nav {
        padding:10px;
        a {font-size:12px;}
        .form-group{
          .p-dropdown{
            .p-dropdown-label,.p-dropdown-item{
              font-size: 12px;
            }
          }
        }
      }
    }
}
</style>
